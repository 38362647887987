<template>
  <div
    v-if="page.length"
    class="relative"
  >
    <img
      :src="isMobile ? page[0].img_m : page[0].img"
      alt=""
      class="width-100"
    >
    <div
      class="width-100"
      style="height:310px;background:#EAF2FA"
    />

    <div
      class="bg-fff"
      :class="isMobile ? 'mlr10 ptb50 relative z1' : 'absolute-center flex-center p50'"
      :style="isMobile ? 'margin-top:-490px; margin-bottom: 46px;' : ''"
    >
      <div
        :style="isMobile ? '' : 'width:490px;'"
        :class="isMobile ? 'tc mlr30' : 'bd br bd-eee pr40'"
      >
        <div class="f40">
          {{ page[0].t1 }}
        </div>
        <div
          class="dib relative z1"
          style="width: 30px;height: 2px;background: green;"
        />
        <div
          class="f18 mt50"
          style="font-weight:lighter"
        >
          {{ page[0].t2 }}
        </div>
      </div>

      <div
        :class="isMobile ? 'mt20' : 'pl50'"
      >
        <div
          class="relative flex items-center"
          :class="isMobile ? 'mlr20' : 'pr50'"
          style="background:#F6F6F6;"
        >
          <div class="plr20 h50 flex-center nowrap">
            微信号
          </div>
          <input
            v-model="wxcode"
            type="text"
            class="h50"
            style="border:none;background:transparent;"
            placeholder="请输入你要查询的微信号"
          >
        </div>
        <div
          :class="isMobile ? 'mt20 flex-center flex-column' : 'flex-center-between mt50 flex-direction-row-reverse'"
        >
          <div class="bg-000 plr20 ptb10 c-fff cp"
               @click="queryWx"
          style="">
            立即查询
          </div>
<!--          <div-->
<!--            class="cp bd bb bd-000"-->
<!--            :class="isMobile ? 'mt20' : ''"-->
<!--          >-->
<!--            举报-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: [],
      wxs: [],
      wxcode:''
    }
  },

  async created () {
    const { data } = await this.fetch('/shouquan.json');
    const { wxs } = await this.fetch('/wxlist.json');
    this.page = data;
    this.wxs = wxs;
  },

  methods: {
    queryWx(){
      this.wxcode = this.wxcode.trim();
      if (this.wxcode === null || this.wxcode === '' || this.wxcode.length < 5){
        alert('请输入正确格式的微信号');
      }else{
        if (this.wxs.includes(this.wxcode)){
          alert('您查询的微信号【是授权账号，可放心购买】！如需帮助请拨打页面下方官方服务热线或关注品牌公众公众号。感谢您对孕大夫的支持与信任！');
        }else{
          alert('您查询的微信号【未经授权，请谨慎购买】！如需帮助请拨打页面下方官方服务热线或关注品牌公众公众号。感谢您对孕大夫的支持与信任！');
        }
      }
    }
  }
}
</script>
